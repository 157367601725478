import Heading1 from "../../components/text/Heading1";
import {AnimatePresence, motion} from "framer-motion";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import MainButton from "../../components/buttons/MainButton";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL, isDevelopmentEnv} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";
import {useNavigate} from "react-router";

interface WelcomeStepProps {
    stepVisible: boolean
}

const WelcomeStep = ({stepVisible}:WelcomeStepProps) => {
    const navigate = useNavigate();

    const [isLoadingPayment, setIsLoadingPayment] = useState(false)

    const [shouldWarn, setShouldWarn] = useState(false)

    useEffect(() => {
        const handleBeforeUnload = (event:any) => {
            if (shouldWarn && !isLoadingPayment) {
                event.preventDefault();
                event.returnValue = ''; // Triggers the confirmation dialog
            }
        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [shouldWarn, isLoadingPayment]); // Dependencies ensure the effect reruns if these change

    useEffect(() => {
        // Example: Set up a click listener to trigger the warning flag
        const handleClick = () => {
            setShouldWarn(true); // Assume some user interaction sets this to true
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const startPaymentFlow = () => {
        setIsLoadingPayment(true)
        setShouldWarn(false)

        axios.post(getBackendURL() + "/api/v1/license/subscribe").then(result => {
            console.log(result)
            if (result.data) {
                window.location.href = result.data
            }
        })
    }

    const skipStripeButtonClicked = () => {
        if (!isDevelopmentEnv()) {
            return
        }

        setShouldWarn(false)
        axios.post(getBackendURL() + "/api/v1/license/skip").then(result => {
            if (result.status === 200) {
                navigate("?step=1")
            }
        }).catch(error => {
            toastError(error)
        })
    }

    return (
        <>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={stepVisible ? { opacity: 1, y: 0 } : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0, y: 50 }}
                    >

                        <img src={"/undraw_happy_announcement_re_tsm0.svg"}
                             className="w-auto"
                             style={{ maxWidth: '300px', maxHeight: '400px'}}
                        />

                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={stepVisible ? { opacity: 1} : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}>
                        <Heading1 className={"mt-5"}>Velkommen til <span className={"text-transparent bg-clip-text bg-gradient-to-b from-main-gradient-first to-main-gradient-second"}>Enthemed</span>!</Heading1>
                        <HeadingDescriptor withoutMargin={true}>Dette skal gå raskt, vi lover!</HeadingDescriptor>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={stepVisible ? { opacity: 1, transition: {delay: 1}} : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}>
                        <MainButton className={"mt-5"} onClickEvent={startPaymentFlow} showProcessingAnimation={isLoadingPayment}>Neste</MainButton>
                        { isDevelopmentEnv() && (
                            <MainButton className={"mt-5"} onClickEvent={skipStripeButtonClicked} showProcessingAnimation={isLoadingPayment}>Skip stripe</MainButton>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default WelcomeStep