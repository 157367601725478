import * as React from "react";
import List from "@mui/material/List";
import {
    HomeIcon,
    BoltIcon,
    BuildingStorefrontIcon,
    Cog8ToothIcon,
    XMarkIcon, UserIcon,
} from "@heroicons/react/24/outline";
import SidebarListItemComponent, {SidebarItem} from "./SidebarListItemComponent";
import {useCurrentUser} from "../../providers/UserProvider";
import DropdownMenu, {MenuOption} from "../input-fields/DropdownMenu";
import {useStoreProvider} from "../../providers/StoreProvider";
import {useEffect, useState} from "react";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import axios from "axios";
import {AnimatePresence, motion} from "framer-motion";
import {Store} from "../../types/Store";
import {useNavigate} from "react-router";
import {FireIcon} from "@heroicons/react/24/solid"; // Importing motion from framer-motion

interface MobileMenuPopoverProps {
    isOpen: boolean
    selectedSidebarItem: string;
    setSelectedSidebarItem: React.Dispatch<React.SetStateAction<string>>;
    closePopover: () => void; // Function to close popover
}

const MobileMenuPopover: React.FC<MobileMenuPopoverProps> = ({

                                                                 isOpen,
                                                                 selectedSidebarItem,
                                                                 setSelectedSidebarItem,
                                                                 closePopover,
                                                             }) => {
    const {currentUser} = useCurrentUser();
    const {store, stores, switchStore} = useStoreProvider()
    const [storesMenuOptions, setStoresMenuOptions] = useState<MenuOption[]>([]);
    const navigate = useNavigate()

    const [sidebarListItems, setSidebarListItems] = useState<SidebarItem[]>([
        {
            text: "Hjem",
            icon: <HomeIcon className="h-8 w-10 shrink-0"/>,
            link: "/",
            selected: () => window.location.pathname == "/"
        },
        {
            text: "Temaer",
            icon: <BuildingStorefrontIcon className="h-8 w-10 shrink-0"/>,
            link: "/themes",
            selected: () => window.location.pathname.includes("/themes")
        },
        {
            text: "Power-ups",
            icon: <BoltIcon className="h-8 w-10 shrink-0"/>,
            link: "/power-ups",
            selected: () => window.location.pathname.includes("/power-ups")
        },
        {
            text: "Innstillinger",
            icon: <Cog8ToothIcon className="h-8 w-10 shrink-0"/>,
            link: "/settings",
            selected: () => window.location.pathname.includes("/settings")
        }
    ])

    useEffect(() => {
        stores && setStoresMenuOptions(stores.map(store => ({title: store.incomingHostname, id: store.id})));
    }, [store]);

    const signOut = () => {
        axios.post(getBackendURL() + "/api/v1/logout").then(() => {
            console.log("200 response on signout");
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("Sign out complete");
            window.location.reload();
        });
    };



    const signOutPressed = () => {
        signOut();
    };

    const addStorePressed = () => {
        navigate("/add-store")
        closePopover()
    }


    const onSidebarItemSelect = (item: SidebarItem) => {
        navigate(item.link)
    }

    useEffect(() => {
        if (currentUser?.roles.map(role => role.id).includes("ADMIN")) {
            console.log("User is admin!")
            if (!sidebarListItems.map(item => item.text).includes("Admin")) {
                setSidebarListItems([...sidebarListItems, {
                    text: "Admin",
                    icon: <FireIcon className="h-8 w-10 shrink-0" />,
                    link: "/admin",
                    selected: () => window.location.pathname.includes("/admin")
                }])
            }
        }
    }, [currentUser]);

    return (
        <div className="fixed flex top-0 left-0 w-screen h-screen justify-center z-50 items-start pt-12 lg:hidden">
            <div onClick={closePopover} className={"fixed top-0 left-0 w-screen h-screen z-40 bg-black opacity-50"}/>
                <motion.div
                    initial={{scale: 0.8, opacity: 0}}
                    animate={{scale: 1, opacity: 1}}
                    exit={{scale: 0.8, opacity: 0}}
                    transition={{
                        ease: "easeOut",
                        duration: 0.2,
                    }}
                    className="bg-white w-11/12 max-w-lg rounded-lg shadow-lg z-50 p-6 space-y-4"
                >
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center space-x-4">
                            <UserIcon className="w-6 h-6 text-gray-400 rounded-full"/>
                            <div>
                                <p className="font-semibold">{currentUser && currentUser.firstName} {currentUser && currentUser.lastName}</p>
                                <p className="text-sm text-gray-600">{currentUser?.activeStore?.name}</p>
                            </div>
                        </div>
                        <button onClick={closePopover} className="text-gray-700">
                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>

                    {/* Menu List */}
                    <List className="space-y-2">
                        {sidebarListItems.map((sidebarItem) => (
                            <SidebarListItemComponent
                                key={sidebarItem.text}
                                sidebarItem={sidebarItem}
                                sidebarVisible={isOpen}
                                onSelect={() => onSidebarItemSelect(sidebarItem)}
                            />
                        ))}
                        <DropdownMenu
                            name={"store"}
                            label={"Butikk"}
                            options={storesMenuOptions}
                            defaultValue={store?.id}
                            onChange={(menuOption) => switchStore!(stores?.filter(b => b.id == menuOption?.id)[0]!)}
                            actionButtonText={"Legg til nettbutikk"}
                            onActionButtonClicked={addStorePressed}
                            hideEmpty={true}
                            icon={"/shopify.svg"}
                        />
                    </List>
                    <div className="mt-4 border-t pt-4">
                        <button onClick={signOutPressed}
                                className="w-full text-center text-sm text-gray-800 hover:underline">
                            Logg ut
                        </button>
                    </div>
                </motion.div>
        </div>
    );
};

export default MobileMenuPopover;
