import Heading1 from "../../components/text/Heading1";
import {AnimatePresence, motion} from "framer-motion";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import MainButton from "../../components/buttons/MainButton";
import React from "react";

interface WelcomeStepProps {
    onNextPressed: () => void;
    stepVisible: boolean;
}

const WelcomeStep = ({ onNextPressed, stepVisible }: WelcomeStepProps) => {

    return (
        <>

                <AnimatePresence>
                    {stepVisible && (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={stepVisible ? { opacity: 1, y: 0 } : {}} // Animate only when dataLoaded is true
                            exit={{ opacity: 0, y: 50 }}
                        >
                            <img
                                src={"/shopify-setup.svg"}
                                className="w-auto"
                                style={{ maxWidth: '300px', maxHeight: '400px' }}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {stepVisible && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={stepVisible ? { opacity: 1 } : {}} // Animate only when dataLoaded is true
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <Heading1 className={"mt-10"}>Legg til Shopify</Heading1>
                            <HeadingDescriptor withoutMargin={true}>
                                Enthemed krever at du bruker Shopify
                            </HeadingDescriptor>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {stepVisible && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={stepVisible ? { opacity: 1, transition: { delay: 1 } } : {}} // Animate only when dataLoaded is true
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="flex flex-col items-center"
                        >
                            <MainButton
                                className={"mt-4"}
                                onClickEvent={onNextPressed}
                            >
                                Legg til Shopify nettbutikk
                            </MainButton>
                        </motion.div>

                    )}
                </AnimatePresence>

        </>
    );
};

export default WelcomeStep;
