export const formatDateIsoString = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("nb-NO", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
};