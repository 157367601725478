import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { PowerUpDTO } from "../../types/PowerUpDTO";

interface PowerUpPreviewContentProps {
    powerUp: PowerUpDTO;
}

type ViewType = 'video' | 'image';
type Direction = number;
type ViewState = [ViewType, Direction];

const PowerUpPreviewContent: React.FC<PowerUpPreviewContentProps> = ({ powerUp }) => {
    const [[view, direction], setView] = useState<ViewState>(['image', 0]);

    const slideVariants = {
        enter: (direction: number) => ({
            x: direction > 0 ? -1000 : 1000, // Endret retning
            opacity: 0
        }),
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction: number) => ({
            zIndex: 0,
            x: direction < 0 ? -1000 : 1000, // Endret retning
            opacity: 0
        })
    };

    const paginate = (newDirection: Direction): void => {
        const nextView: ViewType = view === 'video' ? 'image' : 'video';
        setView([nextView, newDirection]);
    };

    return (
        <div className="w-full mt-6">
            <div className="flex items-center gap-4">
                {/* Left chevron */}
                <button
                    onClick={() => paginate(-1)}
                    className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 shadow-lg transition-colors shrink-0"
                    aria-label="Previous view"
                >
                    <ChevronLeftIcon className="w-6 h-6 text-main-color" />
                </button>

                {/* Content container with fixed aspect ratio */}
                <div className="relative w-full pt-[56.25%] bg-gray-100 rounded-lg overflow-hidden">
                    {/* Content container */}
                    <div className="absolute inset-0">
                        <AnimatePresence initial={false} custom={direction} mode="wait">
                            <motion.div
                                key={view}
                                custom={direction}
                                variants={slideVariants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: { type: "spring", stiffness: 300, damping: 30 },
                                    opacity: { duration: 0.2 }
                                }}
                                className="absolute inset-0"
                            >
                                {view === 'video' ? (
                                    <iframe
                                        key="video"
                                        src={powerUp.videoUrl}
                                        frameBorder="0"
                                        allow="fullscreen"
                                        allowFullScreen
                                        className="w-full h-full bg-white"
                                        title={`${powerUp.title} preview video`}
                                    />
                                ) : (
                                    <img
                                        key="image"
                                        src={powerUp.imageUrl}
                                        alt={`${powerUp.title} preview`}
                                        className="w-full h-full object-contain bg-gray-100"
                                    />
                                )}
                            </motion.div>
                        </AnimatePresence>
                    </div>

                    {/* Indicators */}
                    <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2 z-20">
                        <button
                            onClick={() => setView(['video', direction])}
                            className={`w-2 h-2 rounded-full transition-colors ${
                                view === 'video' ? 'bg-blue-500' : 'bg-gray-300'
                            }`}
                            aria-label="Show video"
                        />
                        <button
                            onClick={() => setView(['image', direction])}
                            className={`w-2 h-2 rounded-full transition-colors ${
                                view === 'image' ? 'bg-blue-500' : 'bg-gray-300'
                            }`}
                            aria-label="Show image"
                        />
                    </div>
                </div>

                {/* Right chevron */}
                <button
                    onClick={() => paginate(1)}
                    className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 shadow-lg transition-colors shrink-0"
                    aria-label="Next view"
                >
                    <ChevronRightIcon className="w-6 h-6 text-main-color" />
                </button>
            </div>
        </div>
    );
};

export default PowerUpPreviewContent;